import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Element, animateScroll as scroll} from 'react-scroll';
import './UHC.css';
import headerImg from './assets/img/Greenify/greenify-header-img.png';
import patientJourneyMapDarkMode from './assets/img/UHC/patient_journey_map_dark_mode.png';
import patientJourneyMapLightMode from './assets/img/UHC/patient_journey_map_light_mode.png';
import empathyStudyImg from './assets/img/UHC/empathy_study_img.png';
import siteVisitImg from './assets/img/UHC/site_visit_photo.png';

function UHC() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const setLightMode = () => {
        setTheme('light');
    };

    const setDarkMode = () => {
        setTheme('dark');
    };

    useEffect(() => {
        const handleScroll = () => {
            // Check if user is at the bottom of the page
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 400, 
            smooth: 'easeInOutQuart',
        });
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 40;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = "menu-name">
                            <Link to = "project-subtitle-greenify" smooth={true} duration={500}>project summary ↘</Link>
                        </li>
                        <li class = "menu-name">branding ↘</li>
                        <li class = "menu-name">marketing ↘</li>
                        <li class = "menu-name">design ↘</li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "menu-name" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <Element class = "project-subtitle" name = "project-subtitle-greenify">CMU MHCI X University Hospitals of Cleveland</Element>
                    <h1 class = "project-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</h1>
                    <div class = "header-img">
                        <img src={headerImg} alt="Greenify Header Image" />
                    </div>
                    <p class = "project-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">7 months <br></br>(January 2024 – July 2024)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Designer</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Tina Chen <br></br> Sophie Davis <br></br> Rachel Sadeh <br></br> Joanne Yang</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS &amp; METHODS</p>
                                <p class = "tag-description">Figma / <br></br> Empathy Study <br></br> Field Research <br></br> Affinity Diagramming <br></br> Speed Dating <br></br> Co-Creation Workshop <br></br> Rapid Prototyping <br></br>Usability Testing</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "buttons">
                            <a href="https://www.figma.com/proto/4FF3xQ0E8BR729stQCjYJA/Sustainable-Design?page-id=22%3A208&type=design&node-id=25-782&viewport=205%2C28%2C0.14&t=pVq9XRoJf3sbN2Ge-1&scaling=min-zoom&starting-point-node-id=25%3A782&mode=design" target="_blank" rel="noopener noreferrer">
                                <button className="button">Prototype →</button>
                            </a>
                        </div>
                    </div>
                    <section class = "content-section" id = "context">
                        <p class = "tag-name">01 CONTEXT</p>
                        <div class = "section-content" id = "context-content">
                            <div class = "section-content-text" id = "context-text">
                                <p>Working alongside University Hospitals of Cleveland, our group of Masters in Human-Computer Interaction students were tasked with improving outcomes for patient-administered wound care following Mohs surgery, with a service that bridges clinic and home.</p>
                                <p><a href="https://www.youtube.com/watch?v=F6TxdvjPk5I" id = "mohs-surgery-link" target="_blank">Mohs surgery↗</a> is essentially a procedure that involves removing the cancer tissues layer by layer. Following Mohs surgery, patients receive both written and verbal instructions and are sent home with medical supplies to manage their care, including performing daily dressing changes for a set period.</p>
                                <p>Below is an initial patient journey map that we created to understand the patient's experience and emotions from the discovery stage to healing, and opportunities that may be found along the way.</p>
                                <img id = "patient-journey-map-img" src={theme === 'light' ? patientJourneyMapLightMode : patientJourneyMapDarkMode} alt="Patient Journey Map" />
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "problem">
                        <p class = "tag-name">02 PROBLEM</p>
                        <div class = "section-content" id = "problem-content">
                            <div class = "method-box">
                                <p class = "problem-text" id = "method-title">METHOD #1: <br></br> 1-Week Empathy Study</p>
                                <img id = "method-img" src = {empathyStudyImg} alt = "Empathy Study Image" />
                                <p class = "problem-text">In our kickoff meeting with clients, we were each given a wound to take care of, offering us a hands-on understanding of post-operative patient experiences. Armed with wound care kits and at-home care procedures, we had our initial dressings on for 48 hours and changed bandages daily for a week.</p>
                                <p class = "problem-text">During this brief wound-care simulation, we each kept a diary and shared our personal challenges and reflections, including adapting to new routines, facing social misconceptions, and coping with persistent itchiness. Through this exercise, we gained valuable insights into some real-world difficulties that patients could face during recovery.</p>
                            </div>
                            <div class = "method-box">
                                <p class = "problem-text" id = "method-title">METHOD #2: <br></br> Site Visit &amp; Patient Interviews</p>
                                <img id = "method-img" src = {siteVisitImg} alt = "Site Visit Image" />
                                <p class = "problem-text">We had an opportunity to visit the University Hospitals and speak with seven patients and do a mock-up with the doctor and nurses. We prepared tailored interview questions, focusing on the key themes we had generated in the first phase of our research. For the mockup session, one of our team members assumed the role of a patient, while the nurses and the doctor recreated the exact experience that a patient would go through following their surgery — dressing their wounds, listening to the at-home wound care instructions and receiving a four-page instruction sheet about what to do. </p>
                                <p class = "problem-text">From the patients we understood that they were leaving the clinic with very high levels of confidence. At the same time however, we were hearing from the nurses that they were receiving numerous calls from patients who seemed to be seeking reassurance once they were home. There was a clear misalignment in patients’ perceptions of their confidence, and their actual wound care proficiency.</p>
                            </div>
                            <div class = "method-box">
                                <p class = "problem-text" id = "method-title">METHOD #3: <br></br> Expert Interviews</p>
                                <img id = "method-img" src = {siteVisitImg} alt = "Expert Interviews Image" />
                                <p class = "problem-text"></p>
                                <p class = "problem-text"></p>
                            </div>
                            <div class = "method-box">
                                <p class = "problem-text" id = "method-title">METHOD #4: <br></br> Speed Dating &amp; Interviews</p>
                                <img id = "method-img" src = {siteVisitImg} alt = "Speed Dating and Interviews Image" />
                                <p class = "problem-text"></p>
                                <p class = "problem-text"></p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Focus Planet</p>
                        <p class = "project-footer-title">Building a Product to Starting a Startup</p>
                        <button class = "read-more-button" onClick={() => navigate('/focusplanet')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">3i Inc.</p>
                        <p class = "project-footer-title">Designing at the Intersection of Hardware and Software</p>
                        <button class = "read-more-button" onClick={() => navigate('/pivo')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
    );
}

export default UHC;