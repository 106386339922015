import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import resume from './assets/Claire Cheong Resume.pdf';

function Header() {

    const [menuOpen, setMenuOpen] = useState(false);
    const [theme, setTheme] = useState('dark');

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const setLightMode = () => {
        setTheme('light');
    };

    const setDarkMode = () => {
        setTheme('dark');
    };
  
    return (
      <header>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>CLAIRE CHEONG</Link>
        <nav className={`menu-list ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to = "/" onClick={toggleMenu}>Works</Link></li>
            <li><a href = {resume} target = "_blank" rel = "noopener noreferrer" onClick = {toggleMenu}>Resume</a></li>
            <li><Link to = "/about" onClick={toggleMenu}>About</Link></li>
            <div class = "header-theme-toggle">
              <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
              <span style={{ margin: '0 10px' }}> / </span>
              <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
            </div>
          </ul>
        </nav>
        <div class = "hamburger-menu" id = "hamburger-menu" onClick={toggleMenu}>
          <span className = {menuOpen ? 'close-icon' : 'menu-icon'}>
          {menuOpen ? '✕' : '≡'}
          </span>
        </div>
      </header>
    );
  }

export default Header;