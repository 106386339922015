import React from 'react';
import './Toggle.css';

function ToggleSwitch({ toggleTheme, isDarkMode }) {

  return (
    <label className="switch">
      <input type="checkbox" onChange={toggleTheme} checked={isDarkMode} />
      <span className={`slider ${isDarkMode ? 'dark' : 'light'}`}></span>
    </label>
  );
}

export default ToggleSwitch;
