import React, { useState, useEffect } from 'react';
import './About.css';
import aboutImg from './assets/img/about-img.png';

function About() {

  const [theme, setTheme] = useState('dark');

  useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const setLightMode = () => {
      setTheme('light');
  };

  const setDarkMode = () => {
      setTheme('dark');
  };


  return (
      <div id = "about-body">
          <div id = "about-left-column">
            <img id = "about-img" src = {aboutImg} alt = "Picture of myself at Andy Warhol Museum" />
            <p id = "about-img-subtext">Picture of myself at Andy Warhol Museum</p>
          </div>
          <div id = "about-main-content">
            <p>As a child, I loved to make things. I was always crafting in my free time, whether it was with yarn, paper, paint, or even recycled objects. My childhood dream was to be an inventor, and I used to jot down whatever ideas I had in my idea diary.</p>
            <p>I was an Economics major in undergrad, but I always knew my passion lay somewhere else. When I had the freedom to choose my general elective classes, I found myself gravitating toward the fields of music, art, and film. It was around my sophomore year at university when I first heard about Information and Interaction Design and jumped headfirst into courses related to this major out of curiosity. </p>
            <p>I greatly enjoyed the classes, but it was the impact of designer Junggi Sung that became a crucial turning point for me to consider a career in user experience design. In a special lecture, Sung introduced his <a href = "https://www.junggisung.com/1588388/faucet" id = "about-link" target = "_blank" rel = "noopener noreferrer">design of a faucet with the water outlet facing toward the user↗</a>. If you turn the water on too much, you will be splashed with water. At that moment, he flipped the script in my understanding of “uncomfortable” design – for the very aspect of failure was in actuality the very feature which transformed a humble faucet into a message about conserving water. The splashing of water encourages people to turn on the faucet with greater care, reducing the total amount of water used. It was enlightening how creative thinking and a simple twist in design could guide human behavior toward a positive direction. </p>
            <p>From that point onward, I have strived to create impactful design that directs people in a positive way. I believe the path that I have walked and will pursue in the future is, and has always been, rooted in my childhood dream of being an inventor. As I have grown to be the designer I am today, my dream has also expanded to include the goal of being an innovator – someone who can transform the world through design.</p>
          </div>
          <div id = "about-theme-toggle">
            <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
            <span style={{ margin: '0 10px' }}> / </span>
            <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
          </div>
      </div>
      

      
);

}

export default About;