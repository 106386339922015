import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './FocusPlanet.css';
import headerImg from './assets/img/FocusPlanet/focusplanet-header-img.png';

function FocusPlanet() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const setLightMode = () => {
        setTheme('light');
    };

    const setDarkMode = () => {
        setTheme('dark');
    };

    useEffect(() => {
        const handleScroll = () => {
            // Check if user is at the bottom of the page
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 400, 
            smooth: 'easeInOutQuart',
        });
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 40;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = "menu-name">
                            <Link to = "/focusplanet#project-subtitle-focusplanet" smooth={true} duration={500}>project summary ↘</Link>
                        </li>
                        <li class = "menu-name">branding ↘</li>
                        <li class = "menu-name">marketing ↘</li>
                        <li class = "menu-name">design ↘</li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "menu-name" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                <p class = "project-subtitle" id = "project-summary">Focus Planet</p>
                    <h1 class = "project-title">Building a Product to Starting a Startup</h1>
                    <div class = "header-img">
                        <img src={headerImg} alt="Focus Planet Header Image" />
                    </div>
                    <p class = "project-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">2+ years<br></br>(April 2022 – Present)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Founder &amp;<br></br>Product Designer <br></br> Contributed to Product Strategy, Branding and Marketing </p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">1 Project Manager<br></br>2 Frontend Developers<br></br>1 Backend Developer</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma<br></br>Adobe Premiere Pro<br></br>Illustrator</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "buttons">
                            <a href="https://www.focusplanet.app" target="_blank" rel="noopener noreferrer">
                                <button className="button">Landing Page →</button>
                            </a>
                            <a href="https://apps.apple.com/kr/app/%ED%8F%AC%EC%BB%A4%EC%8A%A4%ED%94%8C%EB%9E%98%EB%8B%9B-%EB%8F%88%EB%B2%84%EB%8A%94-%EC%A7%91%EC%A4%91%EC%8B%9C%EA%B0%84-%EC%95%B1%ED%85%8C%ED%81%AC/id1597905981" target="_blank" rel="noopener noreferrer">
                                <button className="button">Download App →</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">I don't know</p>
                        <p class = "project-footer-title">I don't know</p>
                        <button class = "read-more-button" onClick={() => navigate('/focusplanet')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
  );

}

export default FocusPlanet;