import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './Greenify.css';
import headerImg from './assets/img/Greenify/greenify-header-img.png';
import surveyImg1DarkMode from './assets/img/Greenify/survey-img1-dark-mode.png';
import surveyImg2DarkMode from './assets/img/Greenify/survey-img2-dark-mode.png';
import surveyImg1LightMode from './assets/img/Greenify/survey-img1-light-mode.png';
import surveyImg2LightMode from './assets/img/Greenify/survey-img2-light-mode.png';
import storyboardImg from './assets/img/Greenify/storyboard.png';
import sketchImg from './assets/img/Greenify/sketch.png';
import solutionImg1 from './assets/img/Greenify/solution-img1.png';
/*import solutionImg2 from './assets/img/Greenify/solution-img2.gif';
import solutionImg3 from './assets/img/Greenify/solution-img3.gif';*/

function Greenify() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const setLightMode = () => {
        setTheme('light');
    };

    const setDarkMode = () => {
        setTheme('dark');
    };

    useEffect(() => {
        const handleScroll = () => {
            // Check if user is at the bottom of the page
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 400, 
            smooth: 'easeInOutQuart',
        });
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 40;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = "menu-name">
                            <Link to="/greenify#project-summary" duration={500}>project summary ↘</Link>
                        </li>
                        <li class = "menu-name">
                            <Link to="/greenify#background" duration={500}>background ↘</Link>
                        </li>
                        <li class = "menu-name">marketing ↘</li>
                        <li class = "menu-name">design ↘</li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "menu-name" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <p class = "project-subtitle" id = "project-summary">Sustainable Design</p>
                    <h1 class = "project-title">Guiding Eco-conscious Choices for Designers</h1>
                    <div class = "header-img">
                        <img src = {headerImg} alt = "Greenify Header Image" />
                    </div>
                    <p class = "project-description">For my final project in Sustainable Design, I proposed the idea of a Figma plugin that could assess the digital carbon footprint of design projects and recommend more eco-friendly alternatives. The tool would evaluate various design elements, such as color, images, and other components that affect energy consumption, providing suggestions to help designers make sustainable decisions.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">3 Weeks <br></br>(Fall 2023)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Designer</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Solo Project <br></br>Advised by Lining Yao</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "buttons">
                            <a href="https://www.figma.com/proto/4FF3xQ0E8BR729stQCjYJA/Sustainable-Design?page-id=22%3A208&type=design&node-id=25-782&viewport=205%2C28%2C0.14&t=pVq9XRoJf3sbN2Ge-1&scaling=min-zoom&starting-point-node-id=25%3A782&mode=design" target="_blank" rel="noopener noreferrer">
                                <button className="button">Prototype →</button>
                            </a>
                        </div>
                    </div>
                    <section class = "content-section" id = "background">
                        <p class = "content-tag">01 BACKGROUND</p>
                        <div class = "section-content" id = "background-content">
                            <div class = "section-content-text" id = "motivation-text">
                                <p>MOTIVATION</p>
                                <p>There are an increasing number of digital services on the market that are aiming to harness technology to encourage sustainable behaviors. While the recent attention on green initiatives undoubtedly indicates a positive change, a question to ask is, <b>what is the environmental cost of creating such digital solutions?</b></p>
                            </div>
                            {/*<div id = "motivation-img">
                                <div id = "cost-and-benefit">
                                    <p id = "cost">Cost</p>
                                    <p id = "benefit">Benefit</p>
                                </div>
                                <div id = "line">―――――――――――――――――――――</div>
                                <div id = "triangle">△</div>
                            </div>*/}
                            <div class = "section-content-text" id = "motivation-text">
                                <p>IMPACT</p>
                                <p>Why is this problem important? In fact, the digital services that we use consume far more energy than we realize. Here are some eye-opening statistics: </p>
                            </div>
                            <div class = "impact-box-wrapper">
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon" id = "plane-icon">✈</div>
                                    <p>Digital carbon footprints are estimated to be about 3.7 percent of global CO₂ emissions, which is equivalent to the emissions of the entire aviation industry</p>
                                </div>
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon">⚘</div>
                                    <p>Globally, the average web page produces approximately 0.8 grams CO2 equivalent per pageview. For a website with 10,000 monthly page views, that's 102 kg CO2e per year. That is amount the amount of carbon that 5 trees absorb in a year.</p>
                                </div>
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon" id = "mail-icon">✉</div>
                                    <p>One less email sent by UK adults a day reduces emissions by 16,433 tons a year. This is equivalent to taking 3,334 diesel cars off the road.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "research">
                        <p class = "content-tag">02 RESEARCH</p>
                        <div class = "section-content" id = "research-content">
                            <div class = "section-content-text" id = "research-text">
                                <p>METHOD 01: SECONDARY RESEARCH</p>
                                <p>I began with a broad exploration, from gathering information about digital carbon footprints and how they are calculated. Then, I identified several key stakeholders involved in this issue: designers, engineers, business management, and the general public. Each of these groups had a unique role in addressing the problem, with specific actions they could take to mitigate their impact.</p>
                                <p>After completing my secondary research, I narrowed my focus to designers as the target audience for the following reasons: </p>
                                <p>1. I discovered that there were specific practices that designers could adopt to help reduce digital carbon footprints. </p>
                                <p>As a designer myself, I thought my background knowledge of the tools and processes used in design could help me see opportunities in incorporating sustainable practices into the design workflow.</p>
                                <p>METHOD 02: USER SURVEYS</p>
                                <p>After identifying designers as my target audience, I focused my research on understanding more about their awareness of the environmental impacts of digital products, sustainable design practices, their willingness to adopt these practices, and the challenges they face in doing so. Due to the time constraints of this school project, I decided to distribute a survey and was able to get 32 responses from designers involved in creating digital products.</p>
                                <div class = "survey-img-wrapper">
                                    <div class = "survey-img-list">
                                        <img id = "survey-img1" src={theme === 'light' ? surveyImg1LightMode : surveyImg1DarkMode} alt="Survey Result 1" />
                                        <img id = "survey-img2" src={theme === 'light' ? surveyImg2LightMode : surveyImg2DarkMode} alt="Survey Result 1" />
                                    </div>
                                </div>
                                <p>INSIGHTS</p>
                                <p>1. There are potential intervention points within the design workflow where sustainable practices can be encouraged. </p>
                                <p>2. Designers were generally unaware that their design choices could affect the energy consumption of digital products.</p>
                                <p>3. While designers see the importance of sustainability, they lack the resources to evaluate the environmental impact of their designs and the steps to create more eco-friendly products.</p>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "define">
                        <div class = "section-content" id = "ideate-content">
                            <div class = "section-content-text" id = "ideate-text">
                                <p>How might we let designers assess the energy efficiency of design choices and offer recommendations for more sustainable options?</p>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "ideate">
                        <p class = "content-tag">03 IDEATE</p>
                        <div class = "section-content" id = "ideate-content">
                            <div class = "section-content-text" id = "ideate-text">
                                <p>My idea was to utilize the form of a Figma plugin. If a tool to help make eco-conscious choices is embedded in the course of designing digital services, I thought that it would streamline the process for designers to evaluate the environmental impact of designs and apply recommended changes right away.</p>
                                <p>Building off the How Might We statement, I used storyboarding to visualize the steps of how designers would use the solution to resolve their problem. </p>
                                <p>My initial thought was to utilize the form of a <b>Figma plugin</b>. If a tool to help make eco-conscious choices is embedded in the course of designing digital services, I thought that it would streamline the process for designers to assess the energy efficiency of their design and apply changes right away. Initially, I only focused on the aspect of <b>assessing and recommending color</b>.</p>
                                <img id = "storyboard-img" src = {storyboardImg} alt = "Storyboard" />
                                <p>After receiving feedback during class, I iterated my idea to include other design elements, such as <b>font, images, and videos</b> and hand-sketched some ideas of how I would organize the information.</p>
                                <img id = "sketch-img" src = {sketchImg} alt = "Sketch" />
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "solution">
                        <p class = "content-tag">04 SOLUTION</p>
                        <div class = "section-content" id = "prototyping-content">
                            <div class = "section-content-text" id = "prototyping-text">
                                <p>Greenify, a Figma Plugin to help designers assess the energy efficiency of design choices and offer recommendations for more sustainable options.</p>
                                <img id = "solution-img1" src = {solutionImg1} alt = "Assess Design" />
                                <p class = "image-subtext">Assess design</p>
                                {/*<div class = "solution-img-wrapper">
                                    <div id = "solution-img2-wrapper">
                                        <img id = "solution-img2" src = {solutionImg2} alt = "Recommend Color" />
                                        <p class = "image-subtext">Recommend Color</p>
                                    </div>
                                    <div id = "solution-img3-wrapper">
                                        <img id = "solution-img3" src = {solutionImg3} alt = "Optimize Image" />
                                        <p class = "image-subtext">Optimize Image</p>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "reflection">
                        <p class = "content-tag">09 REFLECTION</p>
                        <div class = "section-content" id = "reflection-content">
                            <div class = "section-content-text" id = "reflection-text">
                                <p>The learnings from this project has also impacted the way I design - to be more conscious about the design choices I make. An example is this website itself. I have designed this website to promote sustainability by reducing digital carbon footprints that could result from navigating the internet. Specifically, there are mainly three ways in which this is achieved.</p>
                                <p>1. This website defaults to <b>dark mode</b>, which consumes less energy compared to a traditional white background—particularly on mobile devices, where OLED screens are widely used.</p>
                                <p>2. The interface uses a <b>system typeface, Helvetica</b>, to eliminate the need for additional font downloads, thereby reducing energy consumption. Web-safe fonts also work across the widest range of devices and platforms, which improves access to information.</p>
                                <p>3. I have <b>minimized the use of images</b>, especially through the use of <b>Unicode characters</b>. Images and videos are the largest contributors to page weight, but considering the nature of a portfolio website, I did not use ways that would harm the image quality, e.g., by dithering. Instead, I replaced most icons with Unicode characters and also utilized them for the logo of the website, so that they can be displayed on any device without having to download image files.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">3i Inc.</p>
                        <p class = "project-footer-title">Designing at the Intersection of Hardware and Software</p>
                        <button class = "read-more-button" onClick={() => navigate('/pivo')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">Generative Systems for Design</p>
                        <p class = "project-footer-title">Animating Static Album Covers to Bring Music to the Deaf<br /><br /></p>
                        <button class = "read-more-button" onClick={() => navigate('/gen-sys')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
  );

}

export default Greenify;