import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './GenSys.css';
import VideoList from './VideoList';
import headerImg from './assets/img/GenSys/gen-sys-header-img.png';
import animateImg from './assets/img/GenSys/animation.png';
import audioReactiveImg from './assets/img/GenSys/audio-reactive.png';
import surveyImgDarkMode from './assets/img/GenSys/survey-img-dark-mode.png';
import surveyImgLightMode from './assets/img/GenSys/survey-img-light-mode.png';

function GenSys() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const setLightMode = () => {
        setTheme('light');
    };

    const setDarkMode = () => {
        setTheme('dark');
    };

    useEffect(() => {
        const handleScroll = () => {
            // Check if user is at the bottom of the page
            if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 400, 
            smooth: 'easeInOutQuart',
        });
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 40;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = "menu-name">
                            <Link to="/gen-sys#project-summary" duration={500}>project summary ↘</Link>
                        </li>
                        <li class = "menu-name">
                            <Link to="/gen-sys#problem" duration={500}>problem ↘</Link>
                        </li>
                        <li class = "menu-name">
                            <Link to="/gen-sys#method" duration={500}>method ↘</Link>
                        </li>
                        <li class = "menu-name">
                            <Link to="/gen-sys#result" duration={500}>result ↘</Link>
                        </li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "menu-name" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <p class = "project-subtitle" id = "project-summary">Generative Systems for Design</p>
                    <h1 class = "project-title">Animating Static Album Covers to Bring Music to the Deaf</h1>
                    <div class = "header-img">
                        <img src = {headerImg} alt = "Greenify Header Image" />
                    </div>
                    <p class = "project-description">For my final project in Generative Systems for Design, I focused on creating a unique experience that visualizes music for the deaf. To achieve this, I animated static album covers using generative algorithms, utilizing TouchDesigner as my primary tool. Through the interplay of color, motion, and form, I sought to convey the emotions and rhythms of the music, providing a rich visual experience that resonates beyond sound.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">5 Weeks <br></br>(Spring 2024)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Designer, Engineer </p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Solo Project <br></br>Advised by Jingyang Liu</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma<br></br>TouchDesigner</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "buttons">
                            <a href="https://apps.apple.com/kr/app/%ED%8F%AC%EC%BB%A4%EC%8A%A4%ED%94%8C%EB%9E%98%EB%8B%9B-%EB%8F%88%EB%B2%84%EB%8A%94-%EC%A7%91%EC%A4%91%EC%8B%9C%EA%B0%84-%EC%95%B1%ED%85%8C%ED%81%AC/id1597905981" target="_blank" rel="noopener noreferrer">
                                <button className="button">Prototype Demo →</button>
                            </a>
                        </div>
                    </div>
                    <section class = "content-section" id = "problem">
                        <p class = "content-tag">01 PROBLEM</p>
                        <div class = "section-content" id = "problem-content">
                            <div class = "section-content-text" id = "problem-text">
                                <p>For many people, accessing and listening to music is a very simple and easy process. However, music is not something that is fully accessible to people who are deaf. Currently, the main ways in which the deaf perceive music is by feeling the vibrations of the music and watching the sign language interpretation of the song.</p>
                                <p>Many times, deaf people turn music up really, really loud to hear the vibrations of the bass. However, in public spaces, turning music up really loud isn't always feasible due to concerns about noise pollution and disturbance to others. This limitation can affect the experience of deaf individuals who rely on vibrations to perceive music, as they may not be able to access the intensity of vibration they need to fully enjoy the music.</p>
                                <p>Similarly, with mobile devices, there are technical limitations to how intensely vibrations can be produced. While some devices offer vibration settings that can be adjusted, they may not reach the levels necessary for deaf individuals to fully perceive the music, especially in noisy environments.</p>
                                <p>Providing sign language interpretation for lyrics is another important aspect of making music accessible to deaf individuals. However, this accommodation is not always readily available.</p>
                                <p>Inspired by the current introduction of animated album covers on music platforms such as Apple Music or Spotify, I set my project goal to animate album covers to bring music to the deaf. In terms of more specific project goals, I aimed to use dynamic visuals to embody the beat and tempo of the song, conduct lyrical analysis to capture the emotional essence of the music and incorporate it into an existing music platform so that it can be accessed anywhere, anytime.</p>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "method">
                        <p class = "content-tag">02 METHOD</p>
                        <div class = "section-content" id = "method-content">
                            <div class = "section-content-text" id = "method-text">
                                <p>method text</p>
                                <div class = "workflow-img">
                                    <div class = "workflow-img1">
                                        <p class = "workflow-img-title">Animation</p>
                                        <p>1. Take in album cover as image input</p>
                                        <p>2. Animate the image using feedback loop and noise</p>
                                    </div>
                                    <div class = "arrow">·········▸</div>
                                    <div class = "workflow-img2">
                                        <p class = "workflow-img-title">Audio-reactive visuals</p>
                                        <p>1. Take in song as audio input</p>
                                        <p>2. Analyze audio</p>
                                        <p>3. Link audio elements to noise used to animate the static image</p>
                                    </div>
                                    <div class = "arrow">·········▸</div>
                                    <div class = "workflow-img3">
                                        <p class = "workflow-img-title">Emotion Mapping</p>
                                        <p>1. Extract key emotion from lyrics</p>
                                        <p>2. Map emotions to colors</p>
                                        <p>3. Apply color to animated image</p>
                                    </div>
                                </div>
                                <p>Following the workflow, the first step that I took was taking in the image of the album cover as input using Movie File In in TouchDesigner and animating the image using Feedback loop and Noise.</p>
                                <img class = "method-img" src = {animateImg} alt = "TouchDesigner Network for animating static images using Particles GPU" />
                                <p class = "image-subtext">TouchDesigner Network for animating static images using Particles GPU</p>
                                <p>Then, in order to make the image audio-reactive, I took the corresponding song as audio input using Audio File In, combined the two channels, and analyzed the audio using Analyze. Referencing the beat of the music with Math, Lag and Speed, I connected the final Null to the Noise that is used to animate the image.</p>
                                <img class = "method-img" src = {audioReactiveImg} alt = "TouchDesigner Network for making audio-reactive visuals" />
                                <p class = "image-subtext">TouchDesigner Network for making audio-reactive visuals</p>
                                <p>The next step was to conduct the emotion analysis of the lyrical content of the song. Since I had decided to convey emotions through color, my initial task was to associate each emotion with a corresponding color. I created a Google Forms survey asking people to associate seven emotions (anger, disgust, fear, joy, neutral, sadness, and surprise) each with seven colors (red, orange, yellow, green, blue, purple, and pink).</p>
                                <img class = "method-img" src={theme === 'light' ? surveyImgLightMode : surveyImgDarkMode} alt = "Emotion mapping Survey Results" />
                                <p>I received a total of 42 responses and the result was as above. Most people associated anger with red, disgust with green, joy with yellow, sadness with blue, surprise with pink, neutral with purple, and fear with orange.</p>
                                <p>In order to extract key emotion from lyrics, I used an external pipeline named text2emotion to analyze the emotion from the lyrical content. </p>
                                <p>Lastly, I used the result of the emotion mapping to transformed the color of the visual according to the output of the Python code. </p>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "result">
                        <p class = "content-tag">03 RESULT</p>
                        <div class = "section-content" id = "result-content">
                            <VideoList />
                            <div class = "section-content-text" id = "result-text">
                                <p>The last piece of this project was to imagine how this function would be implemented in existing music platforms so that it would be easily accessible. I created a prototype of how this function will be incorporated into a music app, in this case Spotify, using Figma.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Sustainable Design</p>
                        <p class = "project-footer-title">Eco-conscious Choices for Designers</p>
                        <button class = "read-more-button" onClick={() => navigate('/greenify')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">I don't know</p>
                        <p class = "project-footer-title">I don't know<br /><br /></p>
                        <button class = "read-more-button" onClick={() => navigate('/greenify')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
  );

}

export default GenSys;