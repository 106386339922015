import React from 'react';
import { useTable } from 'react-table';

const Table = () => {
  const data = React.useMemo(
    () => [
      { 
        col1: 'Parking Reservation App', 
        col2: 'Guaranteed seating: Passengers could reserve a seat in advance, ensuring they don’t have to stand or compete for seats during their journey.', 
        col3: 'Increased complexity: </br> Managing a reservation system for short trips with frequent stops would be difficult. People board and leave the subway so frequently that enforcing reservations could cause delays and disputes.' },
      { col1: 'Row 2, Col 1', col2: 'Row 2, Col 2', col3: 'Row 2, Col 3' },
      { col1: 'Row 3, Col 1', col2: 'Row 3, Col 2', col3: 'Row 3, Col 3' },
      { col1: 'Row 4, Col 1', col2: 'Row 4, Col 2', col3: 'Row 4, Col 3' },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      { Header: ' ', accessor: 'col1' },
      { Header: 'Pros', accessor: 'col2' },
      { Header: 'Cons', accessor: 'col3' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{ border: 'solid 1px gray', padding: '10px' }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()} style={{ border: 'solid 1px gray', padding: '10px' }}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;